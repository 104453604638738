import { FC } from "react";

interface ILocation {
  className?: string;
}

export const Location: FC<ILocation> = ({ className }) => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7ZM8.5 7C8.5 7.82843 7.82843 8.5 7 8.5C6.17157 8.5 5.5 7.82843 5.5 7C5.5 6.17157 6.17157 5.5 7 5.5C7.82843 5.5 8.5 6.17157 8.5 7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7.5C14 11.5785 10.9554 15.7143 8.88199 18.0578C7.86302 19.2096 6.13698 19.2096 5.11801 18.0578C3.04465 15.7143 0 11.5785 0 7.5C0 3.35786 3.13401 0 7 0C10.866 0 14 3.35786 14 7.5ZM12.5 7.5C12.5 9.18316 11.8646 10.9778 10.9073 12.7011C9.95794 14.41 8.75324 15.9396 7.75855 17.0639C7.33656 17.5409 6.66344 17.5409 6.24145 17.0639C5.24676 15.9396 4.04206 14.41 3.09275 12.7011C2.13543 10.9778 1.5 9.18315 1.5 7.5C1.5 4.08714 4.05823 1.5 7 1.5C9.94177 1.5 12.5 4.08714 12.5 7.5Z"
      />
    </svg>
  );
};
