import { FC, useEffect, useState } from "react";

import moment from "moment";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { EmailAuthModal, OtpEmailObject } from "../../components/security";
import { useTfaContext } from "../../context/TfaContext";
import {
  useFetch,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import { IOtpEmail } from "../../interfaces";
import { templatesApiUrl, TFA_EMAIL_PATH, TFA_PATH } from "../../utils";
import { eventDisableTfa, TfaTypes } from "../../utils/analytics";
import { validateEmail } from "../../utils/validate-email";

export const EmailAuthenticator: FC = () => {
  const { t } = useTranslation();
  const { tfa, setTfaData } = useTfaContext();
  const { visible, toggle } = useToggle();
  const [otpEmailObj, setOtpEmailObj] = useState<OtpEmailObject>();
  const { emailOtpEnabled, emailUsed, emailOtpEnabledDate } = tfa;
  const { isPreviewMode, templateSearchParam } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);
  const { pathname } = useLocation();

  const alias = pathname.split("/")[2];

  const {
    apiCall: getEmailAuthTemplate,
    response: emailAuthTemplate,
    loading: emailAuthTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getEmailAuthTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { apiCall: sendOtpEmail, loading } = useFetch("post");
  const { apiCall: resetOtpEmail, loading: resetLoading } = useFetch("post");

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const sendOtpViaEmail: SubmitHandler<any> = (data: IOtpEmail) => {
    const otpEmailObject = {
      ...data,
      otpCode: "string",
      otpToken: "string",
    };

    sendOtpEmail("ManageApi/SendOtpViaEmail", otpEmailObject, (response) => {
      toast.success(t<string>("2fa/emailAuthenticator.sixDigitCodeSent"));
      setOtpEmailObj(response);
      toggle();
    });
  };

  const resetEmailOtp = () => {
    resetOtpEmail("ManageApi/ResetEmailOtp", {}, () => {
      toast.success(
        t<string>("2fa/emailAuthenticator.emailAuthenticatorReset")
      );
      setTfaData({
        ...tfa,
        emailOtpEnabled: false,
      });
      eventDisableTfa(TfaTypes.email);
      reset();
    });
  };

  const censorWord = (str: string) => {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  };

  const censorEmail = (email?: string) => {
    if (email) {
      var arr = email.split("@");
      return censorWord(arr[0]) + "@" + censorWord(arr[1]);
    }
  };

  const scope = {
    censorEmail,
    resetEmailOtp,
    sendOtpViaEmail,
    handleSubmit,
    emailOtpEnabled,
    emailUsed,
    emailOtpEnabledDate,
    renderPath,
    resetLoading,
    loading,
    register,
    NavLink,
    Icon,
    t,
    TFA_PATH,
    moment,
    validateEmail,
    errors,
    TFA_EMAIL_PATH,
  };

  useEffect(() => {
    if (templateSearchParam === "email-auth-modal") toggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t<string>("2fa/emailAuthenticator.2faEmailAuthenticatorTitle")}
        </title>
        <style>{emailAuthTemplate?.css}</style>
      </Helmet>

      {!emailAuthTemplateLoading && (
        <LiveProvider code={emailAuthTemplate?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}

      {visible && (
        <EmailAuthModal
          visible={visible}
          hide={toggle}
          otpEmailObj={otpEmailObj}
        />
      )}
    </>
  );
};
