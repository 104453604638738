import { FC } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { NavLink } from "react-router-dom";

import { Divider } from "./Divider";
import { cx } from "../../utils";

interface IIPageInfoItem {
  lastChild?: boolean;
  title: string;
  description: any;
  iconName: string;
  to: string;
}

export const PageInfoItem: FC<IIPageInfoItem> = ({
  lastChild,
  title,
  description,
  iconName,
  to,
}) => {
  return (
    <>
      <NavLink
        to={to}
        className={cx([
          "group flex items-start py-8 md:px-6 px-3 rounded-xl transition",
          "hover:bg-gray-50 focus:shadow-outlineGray focus:outline-none",
        ])}
      >
        <img
          src={`images/page-info-icons/${iconName}`}
          alt="Page info icon"
          className="w-8 h-8 md:w-12 md:h-12"
        />

        <div className="flex-1 ml-6 md:pr-12 relative">
          <h6
            className={cx([
              "text-black font-medium transition",
              "group-hover:text-primary",
            ])}
          >
            {title}
          </h6>
          <p className="text-sm text-neutral">{description}</p>

          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 hidden md:block">
            <Icon name="ArrowRight" size={18} />
          </div>
        </div>
      </NavLink>

      {!lastChild && <Divider className="md:my-4 md:ml-24 m-0" />}
    </>
  );
};
