import { FC, ReactNode } from "react";
import { cx } from "../../../utils";

interface ITabs {
  children: ReactNode;
  className?: string;
}

export const Tabs: FC<ITabs> = ({ children, className }) => {
  return (
    <div
      className={cx([
        "tab-list -m-pb flex border border-gray-200 rounded-xl px-7 sm:px-8 justify-between sm:justify-start",
        className && className,
      ])}
    >
      {children}
    </div>
  );
};
