import { FC, useEffect } from "react";

import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink } from "react-router-dom";
import { Loader } from "../../components";
import { Desktop, Location, Phone } from "../../components/icons";
import {
  useFetch,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import {
  cx,
  formatDate,
  MANAGE_DEVICES_PATH,
  templatesApiUrl,
} from "../../utils";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Helmet } from "react-helmet";

export const DeviceDetail: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { toggle, visible } = useToggle();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const {
    apiCall: getDeviceDetails,
    response: device,
    loading: deviceDetailsLoading,
  } = useFetch("get");
  const { apiCall: signoOutFromDevice, loading } = useFetch("post");

  const {
    apiCall: getDeviceDetailsTemplate,
    response: deviceDetailsTemplate,
    loading: deviceDetailsTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getDeviceDetailsTemplate(templatesApiUrl("device-details", isPreviewMode));
    sessionId &&
      getDeviceDetails(
        `Device/GetDeviceSessionDetailsBySessionId?sessionId=${sessionId}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignout = () => {
    signoOutFromDevice(
      `Device/SignOutFromDevice?sessionId=${sessionId}`,
      {},
      () => {
        toast.success(t("manageDevices.successfullyLoggedOut"));
        toggle();
        navigate(MANAGE_DEVICES_PATH);
      }
    );
  };

  if (deviceDetailsLoading) {
    return <Loader />;
  }

  const scope = {
    t,
    cx,
    NavLink,
    renderPath,
    Icon,
    device,
    toggle,
    formatDate,
    Desktop,
    Phone,
    moment,
    Location,
    loading,
    visible,
    onSignout,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("manageDevices.title")}</title>
        <style>{deviceDetailsTemplate?.css}</style>
      </Helmet>

      {!deviceDetailsTemplateLoading && (
        <LiveProvider
          code={deviceDetailsTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
