import { FC, ReactNode } from "react";

import { Sidebar, SubPageHeader } from "../components";
import { Size, useWindowSize } from "../hooks/useWindowSize";
import { ISubPageHeader } from "../interfaces";

interface ISecondaryContainer extends ISubPageHeader {
  children: ReactNode;
}

export const SecondaryContainer: FC<ISecondaryContainer> = (props) => {
  const size: Size = useWindowSize();
  const mobileScreen = !!size.width && size.width < 768;
  return (
    <>
      {mobileScreen && <Sidebar />}
      <div className="max-w-4xl mx-auto px-4">
        <SubPageHeader {...props} />

        {props.children}
      </div>
    </>
  );
};
