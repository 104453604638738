import { FC, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Helmet } from "react-helmet";
import { useTfaContext } from "../../context/TfaContext";
import { useFetch, usePreviewMode } from "../../hooks";
import { templatesApiUrl } from "../../utils";
import { eventEnableTfa, TfaTypes } from "../../utils/analytics";

interface OptObject {
  phoneNumber: string;
  otpCode: string;
  otpToken: string;
}

interface ITextMessageModal {
  visible: boolean;
  hide: () => void;
  optObj?: OptObject;
}

export const TextMessageModal: FC<ITextMessageModal> = ({
  hide,
  optObj,
  visible,
}) => {
  const { t } = useTranslation();
  const { tfa, setTfaData } = useTfaContext();
  const { isPreviewMode } = usePreviewMode();

  const { apiCall: enablePhoneNumber2fa, loading } = useFetch("post");
  const { apiCall: resendOtpViaSMS, loading: resendOtpViaSMSLoading } =
    useFetch("post");
  const {
    apiCall: getTextMessageModalTemplate,
    response: textMessageModalTemplate,
    loading: textMessageModalTemplateLoading,
  } = useFetch("get");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getTextMessageModalTemplate(
      templatesApiUrl("text-message-modal", isPreviewMode)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEnablePhoneNumber2fa = (data: any) => {
    const enablePhoneObject = {
      phoneNumber: optObj?.phoneNumber,
      ...data,
      otpToken: optObj?.otpToken,
    };

    enablePhoneNumber2fa(
      "ManageApi/EnablePhoneNumber2fa",
      JSON.stringify(enablePhoneObject),
      (response) => {
        toast.success(
          `${t("2fa/textMessage.phoneNumberAuthenticatorEnabledSuccessfully")}!`
        );
        setTfaData({
          ...tfa,
          smsOtpEnabled: true,
          phoneUsed: enablePhoneObject.phoneNumber,
          recoveryCodesLeft: 10,
        });
        hide();
        eventEnableTfa(TfaTypes.sms);
      },
      (error) => {
        if (error.response.status === 400) {
          // TODO: should get this text from response instead
          toast.error(t<string>("2fa/textMessage.codeNotValid"));
        }
      }
    );
  };

  const onResendOtpViaSMS = () => {
    const finalOb = {
      phoneNumber: optObj?.phoneNumber,
      otpCode: "string",
      otpToken: "string",
    };

    resendOtpViaSMS("ManageApi/SendOtpViaSMS", finalOb, () => {
      toast.success(t<string>("2fa/textMessage.sixDigitCodeSent"));
    });
  };

  const scope = {
    hide,
    onResendOtpViaSMS,
    register,
    handleSubmit,
    onEnablePhoneNumber2fa,
    visible,
    Icon,
    t,
    errors,
    resendOtpViaSMSLoading,
    loading,
    optObj,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("2fa/textMessage.2faTextMessageTitle")}</title>
        <style>{textMessageModalTemplate?.css}</style>
      </Helmet>

      {!textMessageModalTemplateLoading && (
        <LiveProvider
          code={textMessageModalTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
