import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { IframeWrapper } from "../../components";
import { SITE_LICENCES_PATH, SITE_LICENCE_USERS_PATH } from "../../utils";

export const SiteLicenceList: FC = () => {
  const { t } = useTranslation();
  const [licenceId, setLicenceId] = useState<string>();

  const handleCallback = (licenceId: string) => {
    setLicenceId(licenceId);
  };

  return (
    <>
      {!!licenceId && (
        <Navigate to={`${SITE_LICENCE_USERS_PATH}?lid=${licenceId}`} />
      )}
      <IframeWrapper
        to={SITE_LICENCES_PATH}
        apiUrlDomain="site-licenses"
        pageTitle={t("subscriptions.siteLicences")}
        pageDescription={t("subscriptions.siteLicencesDescription")}
        parentCallback={handleCallback}
      />
    </>
  );
};
