import { FC } from "react";
import { cx } from "../../utils";

interface IPhoneIcon {
  className?: string;
}

export const Phone: FC<IPhoneIcon> = ({ className }) => {
  return (
    <svg
      width="12"
      height="18"
      className={cx(["fill-current", className])}
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.257202 2.99844C0.257202 1.5625 1.42126 0.398438 2.8572 0.398438H9.14291C10.5789 0.398438 11.7429 1.5625 11.7429 2.99844V14.9984C11.7429 16.4344 10.5789 17.5984 9.14292 17.5984H2.8572C1.42126 17.5984 0.257202 16.4344 0.257202 14.9984V2.99844ZM2.8572 1.59844C2.084 1.59844 1.4572 2.22524 1.4572 2.99844V14.9984C1.4572 15.7716 2.084 16.3984 2.8572 16.3984H9.14292C9.91611 16.3984 10.5429 15.7716 10.5429 14.9984V2.99844C10.5429 2.22524 9.91611 1.59844 9.14291 1.59844H2.8572Z"
        fill="#2C2C2C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.20007 14.7094C4.20007 14.378 4.4687 14.1094 4.80007 14.1094H7.22864C7.56002 14.1094 7.82864 14.378 7.82864 14.7094C7.82864 15.0407 7.56002 15.3094 7.22864 15.3094H4.80007C4.4687 15.3094 4.20007 15.0407 4.20007 14.7094Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};
