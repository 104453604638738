import { FC } from "react";
import { useTranslation } from "react-i18next";

import { IframeWrapper } from "../../components";
import { useQuery } from "../../hooks";
import { SITE_LICENCES_PATH } from "../../utils";

export const SiteLicenceUsers: FC = () => {
  const { t } = useTranslation();
  const search = useQuery();
  let licenceId = search.get("lid");

  return (
    <IframeWrapper
      to={SITE_LICENCES_PATH}
      extraParam
      apiUrlDomain={`site-license-users?licenseId=${licenceId}`}
      pageTitle={t("subscriptions.siteLicenceUsers")}
      pageDescription={t("subscriptions.siteLicenceUsersDescription")}
    />
  );
};
