import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { useNavigate } from "react-router-dom";
import { Button, BtnStyle, BtnSize } from "../components";
import { cx, primaryBtnClasses } from "../utils";

export const Page404 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t<string>("notFoundPage.title")}</title>
      </Helmet>
      <div className="error-page-wrapper w-full flex items-center justify-center">
        <div className="flex items-center justify-center flex-col max-w-screen-sm text-center">
          <img
            src="/images/error-indicator.png"
            alt="Unauthorized"
            width={380}
          />
          <h1 className="mb-4 text-primary-mainText font-bold mt-10">
            404 - {t<string>("notFoundPage.title")}
          </h1>
          <h4 className="text-primary-mainText mb-12">
            {t<string>("notFoundPage.description")}
          </h4>

          <div className="flex">
            <Button
              type="button"
              onClick={() => navigate("/")}
              btnStyle={BtnStyle.primary}
              btnSize={BtnSize.nomral}
              className={cx([
                "inline-flex items-center align-middle",
                primaryBtnClasses,
              ])}
            >
              {t<string>("notFoundPage.backButtonText")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
