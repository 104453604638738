import { useEffect, useState } from "react";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Pagination } from "../../components";
import { MainContainer } from "../../containers";
import {
  useCurrentPage,
  useFetch,
  usePreviewMode,
  useRenderPath,
} from "../../hooks";
import {
  getUserInfo,
  templatesApiUrl,
  USER_PROFILE_API_HEADERS,
  USER_PROFILE_CLIENT_API,
} from "../../utils";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Helmet } from "react-helmet";
import { Dropdown, DropdownOption, ThreeDotsButton } from "../../components";

export const Models = () => {
  const PAGE_SIZE = 7;
  const { handlePageChange, currentPage } = useCurrentPage();
  const { slug } = useParams();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const {
    profile: { sub },
  } = getUserInfo();

  const {
    apiCall: getModel,
    response: model,
    loading: modelLoading,
  } = useFetch("get");
  const {
    apiCall: getModelData,
    response: modelData,
    loading,
  } = useFetch("get");
  const { apiCall: executeAction } = useFetch("get");
  const {
    apiCall: getModelTemplateData,
    response: modelTemplate,
    loading: modelTemplateLoading,
  } = useFetch("get");

  const [items, setItems] = useState<any[]>();

  useEffect(() => {
    setItems([]);
    getModel(
      `${USER_PROFILE_CLIENT_API}/${slug}/model`,
      USER_PROFILE_API_HEADERS
    );
    getModelTemplateData(templatesApiUrl(slug || "", isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    getModelData(
      `${USER_PROFILE_CLIENT_API}/${slug}?userId=${sub}&page=${currentPage}&pageSize=${PAGE_SIZE}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, currentPage]);

  useEffect(() => {
    setItems(modelData?.userModelData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData]);

  const executeModelAction = (actionUrl: string) => {
    executeAction(
      `${actionUrl}`,
      {},
      () => {
        getModelData(
          `${USER_PROFILE_CLIENT_API}/${slug}?userId=${sub}&page=${currentPage}&pageSize=${PAGE_SIZE}`
        );
      },
      (errors) => {
        const errsDefault = errors?.response?.data?.errors?.default;
        if (errsDefault)
          Object.values(errsDefault)?.forEach((val: any) => toast.error(val));
      }
    );
  };

  const scope = {
    modelData,
    items,
    model,
    Icon,
    Dropdown,
    DropdownOption,
    ThreeDotsButton,
    executeModelAction,
    handlePageChange,
    Pagination,
    currentPage,
    PAGE_SIZE,
    loading,
    modelLoading,
    renderPath,
  };

  return (
    <>
      <Helmet>
        <style>{modelTemplate?.css}</style>
      </Helmet>
      <MainContainer>
        {!modelTemplateLoading && (
          <LiveProvider code={modelTemplate?.html || "<></>"} scope={scope}>
            <LivePreview />
            <LiveError />
          </LiveProvider>
        )}
      </MainContainer>
    </>
  );
};
