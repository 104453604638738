import { createContext, useState, useContext, useEffect } from "react";
import { useFetch } from "../hooks";

const UserContext = createContext<any>(null);

const UserProfileProvider: React.FC<{ children: any }> = ({ children }) => {
  const [userData, setUserData] = useState<any>();
  const [picture, setPicture] = useState();
  const { apiCall: getUser, loading } = useFetch("get");

  useEffect(() => {
    getUser("ManageApi/UserProfile", {}, (response) => {
      setUserData(response);
      setPicture(response?.picture);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: userData,
        setUserData,
        picture,
        setPicture,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === (undefined || null)) {
    throw new Error("useUserContext must be used within a UserProfileProvider");
  }
  return context;
};

export { UserProfileProvider, useUserContext };
