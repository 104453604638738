import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
//@ts-ignore

import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { createPortal } from "react-dom";
import { Helmet } from "react-helmet";
import { useUserContext } from "../../context/UserContext";
import { useFetch, usePreviewMode } from "../../hooks";
import { templatesApiUrl, validateImage } from "../../utils";

interface IPhotoProfileModal {
  toggle: () => void;
  visible: boolean;
}

export const PhotoProfileModal: FC<IPhotoProfileModal> = ({
  toggle,
  visible,
}) => {
  const { t } = useTranslation();
  const { isPreviewMode } = usePreviewMode();
  const { user, picture, setPicture, setUserData } = useUserContext();
  const { apiCall: updateProfilePhoto, loading } = useFetch("post");
  const { apiCall: deleteProfilePhoto, loading: deleteLoading } =
    useFetch("delete");
  const [image, setImage] = useState<any>();
  const [hasFile, setHasFile] = useState<boolean>(false);

  const {
    apiCall: getProfilePhotoModalTemplate,
    response: profilePhotoModal,
    loading: profilePhotoModalLoading,
  } = useFetch("get");

  useEffect(() => {
    getProfilePhotoModalTemplate(
      templatesApiUrl("change-profile-photo", isPreviewMode)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setImage({
      ...image,
      preview: picture,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onChangePhoto = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      event.target.value = "";
      const validateImageMessage = validateImage(
        file,
        process.env.REACT_APP_PROFILE_IMAGE_FORMAT_ACCEPT || "",
        2097152,
        "1MB"
      );

      if (validateImageMessage?.length) {
        toast.error(validateImageMessage);
      } else {
        setImage(() => ({
          ...image,
          file,
          preview: URL.createObjectURL(file),
          name: file.name,
        }));
        setHasFile(true);
      }
    }
  };

  const onUpdateProfilePhoto = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", image.file);

    updateProfilePhoto(
      "ManageApi/UploadProfilePicture",
      formData,
      (response) => {
        toast.success(`${t("profile.profilePictureUpdatedSuccessfully")}!`);
        setPicture(response);
        setUserData(() => ({
          ...user,
          picture: response,
        }));

        toggle();
      },
      (error) => {
        toast.error(t<string>(error?.response?.data));
      }
    );
  };

  const onDeleteProfilePhoto = () => {
    deleteProfilePhoto("ManageApi/RemoveProfilePicture", {}, () => {
      setImage(null);
      setHasFile(false);
      setPicture(null);
      setUserData(() => ({
        ...user,
        picture: null,
      }));
      toast.success(`${t("profile.profilePictureDeletedSuccessfully")}!`);
    });
  };

  const clearProfilePhoto = () => {
    setImage(null);
    setHasFile(false);
  };

  const IMAGE_FORMAT_ACCEPT =
    process.env.REACT_APP_PROFILE_IMAGE_FORMAT_ACCEPT || "";

  const scope = {
    clearProfilePhoto,
    onDeleteProfilePhoto,
    onUpdateProfilePhoto,
    onChangePhoto,
    loading,
    deleteLoading,
    hasFile,
    isPreviewMode,
    createPortal,
    toggle,
    Icon,
    t,
    image,
    picture,
    IMAGE_FORMAT_ACCEPT,
  };

  return (
    <>
      <Helmet>
        <style>{profilePhotoModal?.css}</style>
      </Helmet>

      {visible &&
        !profilePhotoModalLoading &&
        createPortal(
          <>
            <LiveProvider
              code={profilePhotoModal?.html || "<></>"}
              scope={scope}
            >
              <LivePreview />
              <LiveError />
            </LiveProvider>
          </>,
          document.body
        )}
    </>
  );
};
