import { FC } from "react";
import { cx } from "../../utils";

interface IDivider {
  mSpace?: string;
  className?: string;
}

export const Divider: FC<IDivider> = ({ mSpace, className }) => {
  return (
    <div
      className={cx([
        "border-b border-gray-200",
        mSpace ? mSpace : "my-6 md:mt-24 md:mb-16",
      ])}
    />
  );
};
