import { FC, memo, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useFetch,
  useHasPasswordApi,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import { IChangePassword, IChangePawnPassword } from "../../interfaces";
import { eventChangePassword } from "../../utils/analytics";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { LiveError, LivePreview, LiveProvider } from "react-live-runner";
import { Eye, EyeClosed } from "../../components/icons";
import { NewPasswordField, PasswordField } from "../../components/security";
import { templatesApiUrl } from "../../utils";

export const ChangePassword: FC = memo(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const [formData, setFormData] = useState<IChangePawnPassword>();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const { apiCall: changePassword, loading } = useFetch("post");
  const { apiCall: getPasswordSettings, response: passwordSettings } =
    useFetch("get");
  const { hasPassword, loading: hasPasswordLoading } = useHasPasswordApi(true);
  const { apiCall: changePawnedPassword, loading: pawnPasswordLoading } =
    useFetch("post");
  const {
    apiCall: getChangePasswordTemplate,
    response: changePasswordTemplate,
    loading: changePasswordTemplateLoading,
  } = useFetch("get");

  const alias = pathname.replace("/", "");
  const { toggle, visible } = useToggle();
  const { toggle: infoModalToggle, visible: infoModalVisible } = useToggle();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const newPwdValue = useWatch({
    control,
    name: "newPassword",
  });

  const resetForm = () => {
    reset();
    setTimeout(() => {
      navigate("/security");
    }, 0);
  };

  const onChangePassword: SubmitHandler<any> = (data: IChangePassword) => {
    changePassword(
      `ManageApi/${hasPassword ? "ChangePassword" : "setPassword"}`,
      data,
      (response) => {
        if (!response?.isPasswordPwned) {
          eventChangePassword();
          toast.success(`${t("changePassword.passwordUpdatedSuccessfully")}!`);
          resetForm();
        }
      },
      (error) => {
        const { data } = error?.response;

        if (data?.statusMessage) {
          toast.error(`${t(data?.statusMessage)}!`);
        }

        const canBreachedPasswordContinue =
          data?.isPasswordPwned &&
          data?.enablePasswordPwnValidation &&
          data?.allowPwnedPassword;

        const canNotBreachedPasswordContinue =
          data?.enablePasswordPwnValidation &&
          !data?.allowPwnedPassword &&
          data?.isPasswordPwned;

        if (canBreachedPasswordContinue) {
          const passObject = {
            ...data,
            allowPwnedPassword: true,
            continueWithPwnedPassword: true,
          };
          setFormData(() => passObject);
          toggle();
        }

        if (canNotBreachedPasswordContinue) {
          infoModalToggle();
        }
      }
    );
  };

  const onChangePawnedPassword = () => {
    changePawnedPassword(
      `ManageApi/${hasPassword ? "ChangePassword" : "setPassword"}`,
      formData,
      (response) => {
        if (!response?.isPasswordPwned) {
          eventChangePassword();
          toast.success(
            t<string>("changePassword.passwordUpdatedSuccessfully")
          );
        }

        toggle();
        resetForm();
      },
      (error) => {
        const response = error?.response;
        toggle();
        toast.error(response?.data?.statusMessage);
      }
    );
  };

  useEffect(() => {
    getPasswordSettings(`Users/AuthenticationSettings`);
    getChangePasswordTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scope = {
    t,
    renderPath,
    hasPassword,
    hasPasswordLoading,
    handleSubmit,
    onChangePassword,
    errors,
    passwordSettings,
    loading,
    navigate,
    visible,
    toggle,
    onChangePawnedPassword,
    pawnPasswordLoading,
    infoModalVisible,
    infoModalToggle,
    register,
    methods,
    NewPasswordField,
    Icon,
    NavLink,
    Eye,
    EyeClosed,
    PasswordField,
    FormProvider,
    newPwdValue,
    control,
  };

  return (
    <>
      <Helmet>
        <title>
          {hasPassword
            ? t("changePassword.changePasswordTitle")
            : t("security.setPassowrdTitle")}
        </title>
        <style>{changePasswordTemplate?.css}</style>
      </Helmet>

      {changePasswordTemplateLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      {!changePasswordTemplateLoading && (
        <LiveProvider
          code={changePasswordTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
});
