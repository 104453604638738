import { FC, useEffect, useState } from "react";

import { useAuth } from "oidc-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { NavLink, useLocation } from "react-router-dom";
import {
  useFetch,
  useHasPasswordApi,
  usePreviewMode,
  useRenderPath,
} from "../../hooks";
import { ILoginProvider } from "../../interfaces";
import { templatesApiUrl } from "../../utils";

export const ExternalLogins: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const { hasPassword } = useHasPasswordApi(true);
  const { apiCall: removeExternalLogin } = useFetch("post");

  const alias = pathname.replace("/", "");

  const {
    apiCall: getExternalLoginsTemplate,
    response: externalLoginsTemplate,
    loading: externalLoginsTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getExternalLoginsTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auth = useAuth();
  const userId = auth?.userData?.profile?.sub || "";
  const {
    apiCall: getExternalProviders,
    response: providers,
    loading,
  } = useFetch("get");

  const onRemoveExternalLogin = async (provider: ILoginProvider) => {
    removeExternalLogin(`/Auth/RemoveLoginApi`, provider, () => {
      setShouldUpdate((current) => !current);
      toast.success(`${t("externalLogins.removedSuccessfully")}!`);
    });
  };

  const setupExternalLogin = (provider: string) => {
    window.location.href = `${process.env.REACT_APP_AUTHORITY}/manage/LinkLoginApi?provider=${provider}`;
  };

  useEffect(() => {
    getExternalProviders(`Auth/ExternalLogins?userId=${userId}`);
  }, [shouldUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const providersLength =
    providers?.currentLogins && providers?.currentLogins?.length;

  const scope = {
    providersLength,
    setupExternalLogin,
    onRemoveExternalLogin,
    hasPassword,
    renderPath,
    loading,
    NavLink,
    Icon,
    t,
    providers,
    userId,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("homePage.myConnectedAccounts")}</title>
        <style>{externalLoginsTemplate?.css}</style>
      </Helmet>

      {!externalLoginsTemplateLoading && (
        <LiveProvider
          code={externalLoginsTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
