import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IframeWrapper } from "../../components";

import { useQuery } from "../../hooks";
import { PAYMENTS_PATH } from "../../utils";

export const PaymentsList: FC = () => {
  const { t } = useTranslation();
  const search = useQuery();

  let type = search.get("type");

  const pageTitle =
    type === "info" ? t("payments.paymentsInfo") : t("payments.title");

  return (
    <IframeWrapper
      to={PAYMENTS_PATH}
      apiUrlDomain={`payments/${type}`}
      pageTitle={pageTitle}
      pageDescription={
        type === "info"
          ? t("payments.description")
          : t("payments.paymentHistoryDescription")
      }
    />
  );
};
