import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IframeWrapper } from "../../components";
import { useQuery } from "../../hooks";
import { GIFTS_PATH } from "../../utils";

export const GiftsList: FC = () => {
  const { t } = useTranslation();
  const search = useQuery();

  let type = search.get("type");

  const currentGiftType = (type: string) => {
    switch (type) {
      case "gifts":
        return {
          title: t("gifts.title"),
          description: t("gifts.manageGiftsInnerPage"),
        };

      case "vouchers":
        return {
          title: t("gifts.vouchers"),
          description: t("gifts.manageVouchersInnerPage"),
        };

      case "external-vouchers":
        return {
          title: t("gifts.externalVouchers"),
          description: t("gifts.manageExternalVouchersInnerPage"),
        };

      default:
        return {
          title: "",
          description: "",
        };
    }
  };

  const { title, description } = currentGiftType(type || "gifts");

  return (
    <IframeWrapper
      to={GIFTS_PATH}
      apiUrlDomain={type || ""}
      pageTitle={title}
      pageDescription={description}
    />
  );
};
