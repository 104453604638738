import { FC, ButtonHTMLAttributes, ReactNode } from "react";
import { cx } from "../../utils";

// TODO: should remove helper classes like primaryBtnClasses, and place them here
export enum BtnType {
  primary = `text-white bg-`,
  secondary = "border border-",
  tertiary = "",
}

export enum BtnSize {
  nomral = "py-2",
  medium = "py-3",
  large = "py-4",
}

export enum BtnStyle {
  primary = "primary",
  success = "confirm",
  warning = "warning",
  neutral = "neutral",
  white = "white",
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?: BtnType;
  btnSize?: BtnSize;
  btnStyle?: BtnStyle;
  rounded?: string | boolean;
  children: ReactNode;
}

export const Button: FC<ButtonProps> = ({
  btnType = BtnType.primary,
  btnSize = BtnSize.nomral,
  btnStyle = BtnStyle.primary,
  rounded = true,
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={cx([
        "transition-opacity font-normal outline-none focus:outline-none text-center px-4",
        `${btnType}${btnStyle}`,
        btnType !== BtnType.primary && `text-${btnStyle}`,
        btnSize,
        disabled && "opacity-60 pointer-events-none",
        typeof rounded === "string" ? rounded : rounded && "rounded",
        className,
      ])}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
