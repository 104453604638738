
import {useState} from 'react'

interface ICurrentPage {
  selected: number;
}

export const useCurrentPage = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const handlePageChange = ({ selected: selectedPage }: ICurrentPage) => {
        setCurrentPage(selectedPage + 1);
      };

    return {
        handlePageChange,
        currentPage,
        setCurrentPage,
    };
  };