interface IUserInfo {
    access_token: string;
    expires_at: number;
    id_token: string;
    profile: {
      email: string;
      birthdate: string;
      gender: string;
      firstName: string;
      given_name: string;
      language_code: string;
      email_verified: boolean;
      name: string;
      role: string[] | string;
      sub: string;
    };
}

const userInfoIState = {
  access_token: "",
  expires_at: 0,
  id_token: "",
  profile: {
    birthdate: "",
    firstName: "",
    email: "",
    gender: "",
    given_name: "",
    language_code: "",
    email_verified: false,
    name: "",
    role: [],
    sub: "",
  },
};

export const getUserInfo = ():IUserInfo => {
  let access_token_value:IUserInfo = userInfoIState;

  const _user = window.localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`);
      if (_user) {
    
          const { ...rest } = JSON.parse(_user);
          if (rest) {
            access_token_value = rest
          }
      }

  return access_token_value;
}