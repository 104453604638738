import { useAuth } from "oidc-react";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { NavLink } from "react-router-dom";
import { onlyForRoles, Permission, useUserContext } from "../../context";
import { useFetch, usePreviewMode, useToggle } from "../../hooks";
import useOnclickOutside from "../../hooks/useOnclickOutside";
import { cx, getPostLoginUri, templatesApiUrl } from "../../utils";
import { Dropdown } from "../dropdown";
import { Animation } from "./../animation/index";
import { DropdownOption } from "./DropdownOption";
import SettingsDropdownWrapper from "./SettingsDropdownWrapper";

interface IUserDropdown {
  languages: any;
  currentLangObj: any;
  changeLanguage: any;
}

export const UserDropdown: FC<IUserDropdown> = ({
  languages,
  currentLangObj,
  changeLanguage,
}) => {
  const { admin, appsEditor, usersEditor } = Permission;
  const { t } = useTranslation();
  const isUserAdmin = onlyForRoles([admin, appsEditor, usersEditor]);
  const { visible, toggle } = useToggle();
  const {
    apiCall: getUserDropdownTemplate,
    response: userDropdownTemplate,
    loading: userDropdownTemplateLoading,
  } = useFetch("get");
  const {
    apiCall: getUserDropdownContentTemplate,
    response: userDropdownContentTemplate,
  } = useFetch("get");

  const { isPreviewMode, templateSearchParam } = usePreviewMode();
  const { isPreviewMode: isDropdownContentPreviewMode } = usePreviewMode();

  useEffect(() => {
    getUserDropdownTemplate(templatesApiUrl("userdropdown", isPreviewMode));
    getUserDropdownContentTemplate(
      templatesApiUrl("userdropdowncontent", isPreviewMode)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user, picture } = useUserContext();
  const { email } = !!user && user;

  const auth = useAuth();

  const onSignOut = () => {
    auth?.signOutRedirect();
    getPostLoginUri(false);
  };

  const { ref, isVisible } = useOnclickOutside(false);

  let userDropdownContentHtml = "";
  let userDropdownContentCss = "";

  if (isDropdownContentPreviewMode) {
    userDropdownContentHtml = userDropdownContentTemplate?.previewHtml;
    userDropdownContentCss = userDropdownContentTemplate?.previewCss;
  } else {
    userDropdownContentHtml = userDropdownContentTemplate?.html;
    userDropdownContentCss = userDropdownContentTemplate?.css;
  }

  const userDropdownPreview =
    templateSearchParam === "userdropdown" ||
    templateSearchParam === "userdropdowncontent";

  const onItemClick = () => {
    document.body.click();
  };

  const portaDashboard = process.env.REACT_APP_ADMIN_PAGE;

  const scope = {
    onSignOut,
    changeLanguage,
    currentLangObj,
    languages,
    user,
    picture,
    email,
    Dropdown,
    SettingsDropdownWrapper,
    ref,
    toggle,
    visible,
    cx,
    isVisible,
    Animation,
    userDropdownContentHtml,
    userDropdownContentCss,
    userDropdownPreview,
    NavLink,
    onItemClick,
    DropdownOption,
    t,
    isUserAdmin,
    portaDashboard,
    Icon,
  };

  return (
    <>
      <Helmet>
        <style>{userDropdownTemplate?.css}</style>
      </Helmet>
      {userDropdownTemplateLoading && (
        <div className="flex items-center justify-center py-12 w-full min-w-[288px]">
          <div className="loader"></div>
        </div>
      )}
      {!userDropdownTemplateLoading && (
        <LiveProvider code={userDropdownTemplate?.html} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
