import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Header as HeaderLayout } from "./";

import { Helmet } from "react-helmet";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { toast } from "react-toastify";
import { useOrganizationSettingsContext, useUserContext } from "../../context";
import { useFetch, usePreviewMode, useRenderPath } from "../../hooks";
import { cx, getUserInfo, templatesApiUrl } from "../../utils";
import { Dropdown } from "../dropdown";
import { Dots } from "../icons";
import { ApplicationsDropdown } from "./ApplicationsDropdown";
import { EmailNotVerifiedWarning } from "./EmailNotVerifiedWarning";
import { ILanguage } from "./interface";
import { UserDropdown } from "./UserDropdown";

export const Header = () => {
  const { user, loading, picture } = useUserContext();
  const { organization } = useOrganizationSettingsContext();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const { apiCall: getLanguages, response: languages } = useFetch("get");
  const { apiCall: getDefaultLanguage, response: defaultLang } =
    useFetch("get");
  const { apiCall: setLanguage } = useFetch("post");
  const { apiCall: sendVerificationEmail, loading: verificationLoading } =
    useFetch("post");
  const {
    apiCall: getHeaderTemplate,
    response: headerTemplate,
    loading: headerTemplateLoading,
  } = useFetch("get");

  const {
    profile: { email, given_name },
  } = getUserInfo();

  const onSendVerificationEmail = () => {
    sendVerificationEmail(
      `Users/SendVerificationEmail?email=${email}&firstName=${given_name}`,
      {},
      () => {
        toast.success(t("notConfirmedEmail.verificationSent"));
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const { isEmailConfirmed } = !!user && user;

  const [currentLangObj, setCurrentLangObj] = useState<any>();
  const {
    profile: { language_code },
  } = getUserInfo();

  useEffect(() => {
    getLanguages("Localization/SelectedLanguages");
    getDefaultLanguage("Localization/DefaultLanguage");
    getHeaderTemplate(templatesApiUrl("header", isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentCode =
      localStorage.getItem("lang") !== "undefined"
        ? localStorage.getItem("lang")
        : null;

    const userSelectedLang = languages?.find(
      (lang: ILanguage) =>
        (currentCode || language_code || defaultLang?.languageCode) ===
        lang.languageCode
    );

    setCurrentLangObj(userSelectedLang);

    !Boolean(currentCode) &&
      localStorage.setItem("lang", language_code || defaultLang?.languageCode);

    i18n.changeLanguage(
      currentCode || language_code || defaultLang?.languageCode
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language_code, languages, defaultLang]);

  const changeLanguage = (code: string, lang: ILanguage) => {
    i18n.changeLanguage(code);
    setCurrentLangObj(lang);
    localStorage.setItem("lang", code);
    setLanguage(`Localization/ChangeLanguage?languageCode=${code}`);
  };

  const scope = {
    user,
    Dropdown,
    isEmailConfirmed,
    organization,
    loading,
    UserDropdown,
    EmailNotVerifiedWarning,
    ApplicationsDropdown,
    HeaderLayout,
    NavLink,
    languages,
    currentLangObj,
    changeLanguage,
    onSendVerificationEmail,
    verificationLoading,
    t,
    cx,
    renderPath,
    Dots,
    email,
    picture,
  };

  return (
    <>
      <Helmet>
        <style>{headerTemplate?.css}</style>
      </Helmet>
      {!headerTemplateLoading && (
        <LiveProvider code={headerTemplate?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
