import { bskPush} from "./bskPush";

export enum TfaTypes {
  sms = "sms",
  email = "email",
  authApp = "authenticatorApp"
}

// page views
export const eventPageView = () => bskPush("event", "pageview");


// Number of times when the user has enabled tfa method
export const eventEnableTfa = (type: TfaTypes) =>
  bskPush("event", "enableTfa", { type });

// Number of times when the user has disabled tfa method
export const eventDisableTfa = (type: TfaTypes) =>
bskPush("event", "disableTfa", { type });

// Number of times when the user has changed password
export const eventChangePassword = () =>
  bskPush("event", "changePassword");

// Number of times when the user has downloaded all data
export const eventDownloadPersonalData = () =>
bskPush("event", "downloadPersonalData");

// Number of times when the user has downloaded all data
export const eventDeletePersonalData = () =>
bskPush("event", "deletePersonalData");