import { FC, useEffect, useRef, useState } from "react";

import { useAuth } from "oidc-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { SecondaryContainer } from "../../containers";

interface IframeWrapperProps {
  pageTitle: string;
  to: string;
  pageDescription: string;
  apiUrlDomain: string;
  extraParam?: boolean;
  parentCallback?: (childData: string) => void;
}

export const IframeWrapper: FC<IframeWrapperProps> = ({
  pageTitle,
  to,
  pageDescription,
  apiUrlDomain,
  extraParam,
  parentCallback,
}) => {
  const auth = useAuth();
  const [height, setHeight] = useState();
  const ref = useRef<HTMLIFrameElement>(null);
  const { i18n } = useTranslation();

  const access_token = auth?.userData?.access_token;

  useEffect(() => {
    const handler = (response: any) => {
      let msg = response.data;
      document.body.classList.remove("modal-opened");

      switch (msg.name) {
        case "windowHeight":
          setHeight((prev) => (prev = msg.data.windowHeight));
          break;

        case "modal-opened":
          document.body.classList.add("modal-opened");
          break;

        case "licenseId":
          parentCallback && parentCallback(msg.data.licenseId);
          break;

        case "toast-notification":
          if (msg.data.type === "success") {
            toast.success(msg.data.text);
          } else if (msg.data.type === "error") {
            toast.error(msg.data.text);
          }
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SecondaryContainer title={pageTitle} description={pageDescription} to={to}>
      <iframe
        src={`${process.env.REACT_APP_TONOS_API_URL}/${apiUrlDomain}${
          extraParam ? "&" : "?"
        }lng=${
          i18n?.language === "cs" ? "cz" : i18n?.language
        }&clid=${access_token}`}
        width="100%"
        height={height}
        ref={ref}
        loading="lazy"
        title={pageTitle}
        style={{
          minHeight: "calc(100vh - 255px)",
          zIndex: 1,
          position: "relative",
          backgroundColor: "transparent",
        }}
      ></iframe>
    </SecondaryContainer>
  );
};
