import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown } from "../../components";
import { Desktop, Phone, ThreeDots } from "../../components/icons";
import {
  useFetch,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import { cx, templatesApiUrl } from "../../utils";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import moment from "moment";
import { Helmet } from "react-helmet";

export interface IDevice {
  sessionId: string;
  deviceType: string;
  browser: string;
  currentSession: boolean;
  operatingSystem: string;
  lastActivity: string;
}

export const ManageDevices: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [devices, setDevices] = useState<IDevice[]>();
  const [device, setDevice] = useState<IDevice>();
  const { toggle, visible } = useToggle();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const {
    apiCall: getDevices,
    response,
    loading: getDevicesLoading,
  } = useFetch("get");
  const { apiCall: signoOutFromDevice, loading } = useFetch("post");

  const {
    apiCall: getManageDevicesTemplate,
    response: manageDevicesTemplate,
    loading: manageDevicesTemplateLoading,
  } = useFetch("get");

  const alias = pathname.replace("/", "");

  useEffect(() => {
    getDevices("Device/GetDeviceSessionsForUser");
    getManageDevicesTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDevices(response?.activeDeviceSessions);
  }, [response]);

  const onSignout = () => {
    signoOutFromDevice(
      `Device/SignOutFromDevice?sessionId=${device?.sessionId}`,
      {},
      () => {
        const filteredDevices = devices?.filter(
          (deviceItem) => deviceItem.sessionId !== device?.sessionId
        );
        setDevices(filteredDevices);

        toast.success(t("manageDevices.successfullyLoggedOut"));
        toggle();
      }
    );
  };

  const scope = {
    getDevicesLoading,
    t,
    NavLink,
    renderPath,
    Icon,
    devices,
    cx,
    Desktop,
    Phone,
    ThreeDots,
    moment,
    Dropdown,
    toggle,
    setDevice,
    onSignout,
    loading,
    visible,
    device,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("manageDevices.title")}</title>
        <style>{manageDevicesTemplate?.css}</style>
      </Helmet>

      {!manageDevicesTemplateLoading && (
        <LiveProvider
          code={manageDevicesTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
