import { FC } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormFooter, Input, InputSize, Label, Textarea } from "../components";
import { MainContainer } from "../containers";
import { useFetch } from "../hooks";
import { cx, inputClasses, textareaClasses } from "../utils";

export const CreateTemplate: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { apiCall: createTemplate, loading } = useFetch("post");

  const onTemplateCreate = (data: any) => {
    const templateObj = {
      ...data,
      type: "Default",
      defaultHtml: data?.html,
      defaultCss: data?.css,
      previewHtml: data?.html,
      previewCss: data?.css,
      updatedBy: "teuta@gjirafa.com",
    };

    createTemplate(
      `https://templates.porta.gjirafa.dev/api/v2/templates/createtemplate`,
      templateObj,
      () => {
        toast.success("Template created successfully!");
        reset();
      },
      (error) => {
        const errs = error?.response?.data?.Errors;
        const errsDefault = error?.response?.data?.errors?.default;

        if (errs)
          Object.values(errs)?.forEach((val: any) => toast.error(val[0]));

        if (errsDefault)
          Object.values(errsDefault)?.forEach((val: any) => toast.error(val));
      }
    );
  };

  return (
    <MainContainer>
      <h1>Create Template</h1>

      <form action="">
        <div className="mb-4 mt-5">
          <Label className="mb-1">Template Name</Label>
          <Input
            {...register("name", { required: "Template name is required" })}
            error={!!errors.name && errors.name.message}
            inputSize={InputSize.sm}
            type="text"
            className={inputClasses}
            maxLength={150}
          />
        </div>
        <div className="mb-4">
          <Label className="mb-1">Alias</Label>
          <Input
            {...register("alias", { required: "Alias is required" })}
            error={!!errors.alias && errors.alias.message}
            inputSize={InputSize.sm}
            type="text"
            className={inputClasses}
            maxLength={150}
          />
        </div>
        <div className="mb-4">
          <Label className="mb-1">Organization</Label>
          <Input
            {...register("organization")}
            error={!!errors.organization && errors.organization.message}
            inputSize={InputSize.sm}
            type="text"
            className={inputClasses}
            maxLength={150}
          />
        </div>
        <div className="mb-4">
          <Label className="mb-1">Icon</Label>
          <Input
            {...register("icon", {
              required: "Icon is required",
            })}
            inputSize={InputSize.sm}
            error={!!errors.icon && errors.icon.message}
            type="text"
            className={inputClasses}
            maxLength={150}
          />
        </div>
        <div className="mb-4">
          <Label className="mb-1">HTML</Label>
          <Textarea
            {...register("html", {
              required: "HTLML is required",
            })}
            error={!!errors.html && errors.html.message}
            placeholder=""
            className={cx(["textarea-form-control", textareaClasses])}
          />
        </div>

        <div className="mb-4">
          <Label className="mb-1">CSS</Label>
          <Textarea
            {...register("css", {
              required: "CSS is required",
            })}
            error={!!errors.css && errors.css.message}
            placeholder=""
            className={cx(["textarea-form-control", textareaClasses])}
          />
        </div>

        <FormFooter
          onSaveClick={handleSubmit(onTemplateCreate)}
          disabled={loading}
          loading={loading}
        />
      </form>
    </MainContainer>
  );
};
