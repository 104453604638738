import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

interface IDropdownOption {
  isLink?: boolean;
  onClick?: () => void;
  iconName: string;
  to?: string;
  iconColor?: string;
  className?: string;
  children: ReactNode;
}

export const DropdownOption: FC<IDropdownOption> = ({
  isLink,
  children,
  onClick,
  iconName,
  to,
  iconColor,
  className,
}) => {
  const classNames = cx([
    "py-3 px-4 transition flex flex-1 items-center group rounded w-full focus:bg-gray-200 break-all",
    "text-sm text-gray-700",
    "hover:bg-gray-100",
  ]);
  return (
    <>
      {isLink ? (
        <NavLink
          to={to || ""}
          className={cx([classNames, className])}
          onClick={onClick}
        >
          <div className={cx(["mr-5 group-hover:text-primary", iconColor])}>
            <Icon name={iconName} size={22} />
          </div>
          {children}
        </NavLink>
      ) : (
        <button
          type="button"
          onClick={onClick}
          className={cx([classNames, className])}
        >
          <div className={cx(["mr-5 group-hover:text-primary", iconColor])}>
            <Icon name={iconName} size={22} />
          </div>
          {children}
        </button>
      )}
    </>
  );
};
