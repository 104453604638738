import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useFetch } from "../../hooks";
import { cx, getUserInfo } from "../../utils";
import { Spinner, SpinnerSize } from "../ui/Spinner";

export const EmailNotVerifiedWarning = () => {
  const { t } = useTranslation();
  const { apiCall: sendVerificationEmail, loading } = useFetch("post");
  const {
    profile: { email, given_name },
  } = getUserInfo();

  const onSendVerificationEmail = () => {
    sendVerificationEmail(
      `Users/SendVerificationEmail?email=${email}&firstName=${given_name}`,
      {},
      () => {
        toast.success(t("notConfirmedEmail.verificationSent"));
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <div
      className="py-3 px-4 text-white font-medium flex items-center justify-center mb-8"
      style={{
        background:
          "radial-gradient(93.94% 7239.66% at 73.09% 71.97%, rgba(69, 171, 138, 0.9) 27.6%, rgba(69, 171, 138, 0.99) 63.02%, #45AB8A 100%)",
        boxShadow: "0px 12px 24px rgba(214, 213, 213, 0.24)",
      }}
    >
      <p className="text-sm">{t("notConfirmedEmail.confirmEmailMessage")}</p>
      <button
        disabled={loading}
        type="button"
        onClick={onSendVerificationEmail}
        className={cx([
          "flex items-center rounded text-sm px-3 py-1 ml-6 border border-white hover:cursor-default transition",
          loading && "opacity-70 cursor-default",
          !loading && "hover:bg-white hover:text-primary-mainText",
        ])}
      >
        {loading && (
          <Spinner
            size={SpinnerSize.xs}
            color="text-white"
            className="mr-2"
            padding="p-0"
          />
        )}

        {t<string>("recoverEmail.resend")}
      </button>
    </div>
  );
};
