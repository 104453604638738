interface IManageFeatures {
    recoveryEmail: boolean;
    profileInfo: boolean;
    connectedAccounts: boolean;
    downloadData: boolean;
    deleteData: boolean;
    changePassword: boolean;
    emailVerification: boolean;
    smsVerification: boolean;
    authAppVerification: boolean;
    twoFactorAuth: boolean;
    security: boolean;
    personalized: boolean;
    language: boolean;
    myAccount: boolean;
    contactSupport: boolean;
    subscriptions: boolean;
    payments: boolean;
    gifts: boolean;
    licences: boolean;
    manageDevices: boolean;
    permissions: boolean;
}

const getEnvVariableValue = (variable: string) => {
  let value = false;
  value = process.env[variable] === "true" ? true : false

  return value;
}

/* 
  The purpose of this object is to check the value for some specific variables from .env file.
  Those specific variables will hold boolean value, in order to tell which feature can be visible to a specific client
**/

export const featureEnabled: IManageFeatures = {
  recoveryEmail: getEnvVariableValue("REACT_APP_ENABLE_RECOVERY_EMAIL"),
  profileInfo: getEnvVariableValue("REACT_APP_ENABLE_PROFILE_INFO"),
  connectedAccounts: getEnvVariableValue("REACT_APP_ENABLE_CONNECTED_ACCOUNTS"),
  downloadData: getEnvVariableValue("REACT_APP_ENABLE_DOWNLOAD_DATA"),
  deleteData: getEnvVariableValue("REACT_APP_ENABLE_DELETE_DATA"),
  changePassword: getEnvVariableValue("REACT_APP_ENABLE_CHANGE_PASSWORD"),
  twoFactorAuth: getEnvVariableValue("REACT_APP_ENABLE_TWO_FACTOR_AUTH"),
  emailVerification: getEnvVariableValue("REACT_APP_ENABLE_EMAIL_VERIFICATION"),
  smsVerification: getEnvVariableValue("REACT_APP_ENABLE_SMS_VERIFICATION"),
  authAppVerification: getEnvVariableValue("REACT_APP_ENABLE_AUTH_APP_VERIFICATION"),
  security: getEnvVariableValue("REACT_APP_ENABLE_SECURITY"),
  personalized: getEnvVariableValue("REACT_APP_ENABLE_PERSONALIZED"),
  language: getEnvVariableValue("REACT_APP_ENABLE_LANGUAGE"),
  myAccount: getEnvVariableValue("REACT_APP_ENABLE_MY_ACCOUNT"),
  contactSupport: getEnvVariableValue("REACT_APP_ENABLE_CONTACT_SUPPORT"),
  subscriptions: getEnvVariableValue("REACT_APP_ENABLE_SUBSCRIPTIONS"),
  payments: getEnvVariableValue("REACT_APP_ENABLE_PAYMENTS"),
  gifts: getEnvVariableValue("REACT_APP_ENABLE_GIFTS"),
  licences: getEnvVariableValue("REACT_APP_ENABLE_LICENCES"),
  manageDevices: getEnvVariableValue("REACT_APP_ENABLE_MANAGE_DEVICES"),
  permissions: getEnvVariableValue("REACT_APP_ENABLE_PERMISSIONS"),
};

export const myAccountDisabled =  
(featureEnabled["profileInfo"] === false &&
featureEnabled["connectedAccounts"] === false) || featureEnabled["myAccount"] === false;

export const personalizationDisabled =  
 (featureEnabled["downloadData"] === false &&
 featureEnabled["deleteData"] === false) || featureEnabled["personalized"] === false;

export const tfaDisabled = 
featureEnabled["emailVerification"] === false &&
  featureEnabled["authAppVerification"] === false &&
  featureEnabled["smsVerification"] === false;


export const securityDisabled = 
(featureEnabled["changePassword"] === false &&
 featureEnabled["twoFactorAuth"] === false && featureEnabled["recoveryEmail"] === false ) || featureEnabled["security"] === false