import { useSearchParams } from "react-router-dom";

export const usePreviewMode = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  
  const modeSearchParam = searchParams.get("mode");
  const templateSearchParam = searchParams.get("template");
  const isPreviewMode = modeSearchParam === 'preview';


  return {
    isPreviewMode,
    searchParams,
    templateSearchParam,
    setSearchParams
  };
};
