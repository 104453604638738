import { cx } from "./classnames";

export const inputClasses =
  "text-primary-mainText transition focus:shadow-primary hover:border-gray-400";
  
  export const primaryBtnClasses = cx([
    "sm:font-normal sm:transition sm:px-4 py-2",
    "hover:bg-primary-hover hover:border-primary-300",
    "focus:shadow-outlinePrimary",
    "active:bg-primary-pressed",
  ]);
  
  export const cancelBtnClasses = cx([
    "sm:font-normal sm:transition sm:border-primary-stroke sm:px-4 sm:text-primary-mainText bg-white py-2",
    "hover:border-gray-400",
    "focus:shadow-outlineGray",
    "active:shadow-outlineGray active:bg-gray-50",
  ]);
  
  export const warningBtnClasses = cx([
    "sm:font-normal sm:transition sm:px-4 py-2",
    "hover:bg-warning-hover hover:border-warning-300",
    "focus:shadow-outlineWarning",
    "active:bg-warning-pressed",
  ]);

  export const textareaClasses =
  "resize-none border-primary-stroke transition focus:shadow-primary hover:border-gray-400 text-primary-mainText py-2";