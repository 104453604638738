import { ButtonHTMLAttributes } from "react";
import { cx } from "../../utils";
import { ThreeDots } from "../icons";

interface IThreeDotsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const ThreeDotsButton: React.FC<IThreeDotsButton> = ({
  className,
  type = "button",
  ...props
}) => {
  return (
    <button
      type={type}
      className={cx([
        "w-7 h-7 rounded hover:border-primary-stroke border border-transparent trnasition hover:text-primary-mainText flex items-center justify-center focus:outline-none focus:shadow-outlinePrimary active:shadow-outlinePrimary",
        className,
      ])}
      {...props}
    >
      <div>
        <ThreeDots width={16} height={6} />
      </div>
    </button>
  );
};
