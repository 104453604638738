import { useState, SetStateAction, Dispatch } from 'react';

interface IToggleProps  {
  visible: boolean;
  toggle: () => void;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

export const useToggle = (): IToggleProps => {
  const [visible, setVisible] = useState(false);

  function toggle(): void {
    setVisible(!visible);
  }

  return {
    visible,
    toggle,
    setVisible,
  };
};
