import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation } from "react-router-dom";
import { MainContainer } from "../../containers";
import {
  useFetch,
  useHasPasswordApi,
  usePreviewMode,
  useRenderPath,
} from "../../hooks";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { SectionLoader } from "../../components/account";
import { templatesApiUrl } from "../../utils";

export const Security: FC = () => {
  const { t } = useTranslation();
  const { hasPassword, loading } = useHasPasswordApi(true);
  const { pathname } = useLocation();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const alias = pathname.slice(1);

  const {
    apiCall: getSecurityTemplate,
    response: securityTemplate,
    loading: securityTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getSecurityTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scope = {
    t,
    NavLink,
    Icon,
    hasPassword,
    loading,
    SectionLoader,
    renderPath,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("security.securityTitle")}</title>
        <style>{securityTemplate?.css}</style>
      </Helmet>

      <MainContainer>
        {!securityTemplateLoading && (
          <LiveProvider code={securityTemplate?.html || "<></>"} scope={scope}>
            <LivePreview />
            <LiveError />
          </LiveProvider>
        )}
      </MainContainer>
    </>
  );
};
