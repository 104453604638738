import { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

const TfaContext = createContext<any>(null);

const TfaProvider: React.FC<{ children: any }> = ({ children }) => {
  const [tfaData, setTfaData] = useState<object>({});

  const getTFA = async () => {
    const response = await axios(`ManageApi/TwoFactorAuthentication`);
    setTfaData(response.data);
  };

  useEffect(() => {
    getTFA();
  }, []);

  return (
    <TfaContext.Provider
      value={{
        tfa: tfaData,
        setTfaData,
      }}
    >
      {children}
    </TfaContext.Provider>
  );
};

const useTfaContext = () => {
  const context = useContext(TfaContext);
  if (context === (undefined || null)) {
    throw new Error("useTfaContext must be used within a TfaProvider");
  }
  return context;
};

export { TfaContext, TfaProvider, useTfaContext };
