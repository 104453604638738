import { FC } from "react";
import { NavLink } from "react-router-dom";
import { cx } from "../../../utils";

interface ITabItem {
  path: string;
  label: string;
}

export const Tab: FC<ITabItem> = ({ path, label }) => {
  return (
    <div className="mr-2 sm:mr-10 text-gray-700">
      <NavLink
        to={path}
        className={({ isActive }) =>
          cx([
            "inline-block py-5 transition",
            "text-sm leading-4 font-medium",
            "hover:text-primary",
            isActive
              ? "border-primary border-b-2 text-primary"
              : "text-primary-secText",
          ])
        }
        // activeClassName="border-primary border-b-2 text-primary"
        // className={cx([
        //   "inline-block py-5 transition",
        //   "text-sm leading-4 font-medium",
        //   "hover:text-primary",
        // ])}
      >
        {label}
      </NavLink>
    </div>
  );
};
