import { FC, useEffect, useState } from "react";

import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation } from "react-router-dom";
import { Animation } from "../../components";
import { useOrganizationSettingsContext } from "../../context/OrganizationSettingsContext";
import { useFetch, usePreviewMode, useRenderPath } from "../../hooks";
import { eventDownloadPersonalData } from "../../utils/analytics";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Check } from "../../components/icons";
import { templatesApiUrl } from "../../utils";

export const DownloadData: FC = () => {
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { organization } = useOrganizationSettingsContext();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);
  const {
    apiCall: downloadUserData,
    response: userData,
    loading,
  } = useFetch("post");
  const {
    apiCall: getDownloadStatus,
    response: downloadStatus,
    loading: downloadStatusLoading,
  } = useFetch("get");

  const {
    apiCall: getDownloadDataTemplate,
    response: downloadDataTemplate,
    loading: downloadDataTemplateLoading,
  } = useFetch("get");

  const alias = pathname.replace("/", "");

  useEffect(() => {
    getDownloadDataTemplate(templatesApiUrl(alias, isPreviewMode));
    getDownloadStatus("ManageApi/DownloadUserData");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestDownload = () => {
    downloadUserData(
      "ManageApi/DownloadUserData",
      {},
      () => {
        setDownloaded(true);
        eventDownloadPersonalData();
      },
      (error) => {
        console.log("error respoinse", error?.response?.data);
      }
    );
  };

  const dateUserDataExportAllowed =
    !!downloadStatus?.dateUserDataExportAllowed &&
    format(new Date(downloadStatus?.dateUserDataExportAllowed), "dd MMM, yyyy");

  const scope = {
    loading,
    downloaded,
    downloadStatus,
    downloadStatusLoading,
    t,
    renderPath,
    Icon,
    NavLink,
    userData,
    organization,
    dateUserDataExportAllowed,
    Animation,
    requestDownload,
    Check,
  };

  return (
    <>
      <Helmet>
        <title>{t("downloadData.downloadDataTitle")}</title>
        <style>{downloadDataTemplate?.css}</style>
      </Helmet>

      {!downloadDataTemplateLoading && (
        <LiveProvider
          code={downloadDataTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
