import { FC, useEffect } from "react";

import moment from "moment";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import QRCode from "react-qr-code";
import { useTfaContext } from "../../context/TfaContext";
import { useFetch, usePreviewMode, useRenderPath } from "../../hooks";
import { IEnableAuthenticator } from "../../interfaces";
import {
  templatesApiUrl,
  TFA_PATH,
  TFA_RECOVERY_CODES_PATH,
} from "../../utils";
import {
  eventDisableTfa,
  eventEnableTfa,
  TfaTypes,
} from "../../utils/analytics";

export const AuthenticatorApp: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const alias = pathname.split("/")[2];
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);
  const { tfa, setTfaData } = useTfaContext();
  const { hasAuthenticator, authenticatorEnabledDate, recoveryCodesLeft } =
    !!tfa && tfa;
  const {
    apiCall: getAuthenticaor,
    response: qrCodeResponse,
    loading,
  } = useFetch("get");

  const {
    apiCall: getAuthenticatorAppTemplate,
    response: authenticatorAppTemplate,
    loading: authenticatorAppTemplateLoading,
  } = useFetch("get");

  const { apiCall: saveAuthenticator, loading: saveAuthLoading } =
    useFetch("post");
  const { apiCall: resetAuthenticator, loading: resetAuthLoading } =
    useFetch("post");

  const AUTH_API = "ManageApi/EnableAuthenticator";

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getAuthenticatorAppTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAuthenticaor(AUTH_API);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAuthLoading]);

  const onSaveAuthenticator: SubmitHandler<any> = (
    data: IEnableAuthenticator
  ) => {
    saveAuthenticator(
      AUTH_API,
      data,
      (response) => {
        toast.success(
          `${t("2fa/authenticatorApp.authenticatorAppUpdatedSuccessfully")}!`
        );
        if (!!recoveryCodesLeft && recoveryCodesLeft > 0) {
          setTfaData({
            ...tfa,
            hasAuthenticator: true,
            authenticatorEnabledDate: response,
            recoveryCodesLeft: 10,
          });
        } else {
          setTfaData({
            ...tfa,
            hasAuthenticator: true,
            recoveryCodesLeft: 10,
            authenticatorEnabledDate: new Date(),
          });

          navigate(TFA_RECOVERY_CODES_PATH, {
            state: {
              codes: response,
            },
          });
        }

        reset();
        eventEnableTfa(TfaTypes.authApp);
      },
      (error) => {
        if (error?.response?.status === 400) {
          toast.error(t<string>("2fa/textMessage.codeNotValid"));
        }
      }
    );
  };

  const onResetAuthenticator = () => {
    resetAuthenticator(`ManageApi/resetAuthenticator`, {}, () => {
      toast.success(
        t<string>("2fa/authenticatorApp.authenticatorAppResetSuccessfully")
      );
      setTfaData({
        ...tfa,
        hasAuthenticator: false,
      });
      eventDisableTfa(TfaTypes.authApp);
    });
  };

  const scope = {
    onResetAuthenticator,
    onSaveAuthenticator,
    saveAuthLoading,
    register,
    handleSubmit,
    errors,
    Icon,
    QRCode,
    NavLink,
    TFA_PATH,
    hasAuthenticator,
    authenticatorEnabledDate,
    loading,
    qrCodeResponse,
    renderPath,
    t,
    moment,
    resetAuthLoading,
  };

  return (
    <>
      <Helmet>
        <title>{t("2fa/authenticatorApp.2faAuthenticatorAppTitle")}</title>
        <style>{authenticatorAppTemplate?.css}</style>
      </Helmet>

      {!authenticatorAppTemplateLoading && (
        <LiveProvider
          code={authenticatorAppTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
