const SAVED_URI = 'PREV_PATH';
const DEFAULT_URI = '/';

export const setPostLoginUri = () => {
  const currentHash = window.location.hash;
  const currentPath = window.location.pathname;
  const savedURI = sessionStorage.getItem(SAVED_URI);

  if (savedURI) {
    return;
  }

  sessionStorage.setItem(SAVED_URI, currentPath + currentHash);
}

export const getPostLoginUri = (retain: boolean) => {
  const savedURI = sessionStorage.getItem(SAVED_URI);

  if (!retain) {
    sessionStorage.removeItem(SAVED_URI);
  }

  return savedURI || DEFAULT_URI;
}
