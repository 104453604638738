import { FC } from "react";
import { cx } from "../../utils";

interface IDesktopIcon {
  className?: string;
}

export const Desktop: FC<IDesktopIcon> = ({ className }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(["fill-current", className])}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.399902 2.85781C0.399902 1.42187 1.56396 0.257812 2.9999 0.257812H14.9999C16.4358 0.257812 17.5999 1.42187 17.5999 2.85781V11.4292C17.5999 12.8652 16.4358 14.0292 14.9999 14.0292H2.9999C1.56396 14.0292 0.399902 12.8652 0.399902 11.4292V2.85781ZM2.9999 1.45781C2.2267 1.45781 1.5999 2.08461 1.5999 2.85781V11.4292C1.5999 12.2024 2.2267 12.8292 2.9999 12.8292H14.9999C15.7731 12.8292 16.3999 12.2024 16.3999 11.4292V2.85781C16.3999 2.08461 15.7731 1.45781 14.9999 1.45781H2.9999Z"
        fill="#2C2C2C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.54272 15.1469C5.54272 14.8155 5.81135 14.5469 6.14272 14.5469H11.857C12.1884 14.5469 12.457 14.8155 12.457 15.1469C12.457 15.4782 12.1884 15.7469 11.857 15.7469H6.14272C5.81135 15.7469 5.54272 15.4782 5.54272 15.1469Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};
