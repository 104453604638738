import { FC } from "react";
import ContentLoader from "react-content-loader";
import { Size, useWindowSize } from "../../hooks/useWindowSize";

interface ISectionLoader {
  itemsCount: number;
}

export const SectionLoader: FC<ISectionLoader> = ({ itemsCount }, props) => {
  const size: Size = useWindowSize();
  const mobileScreen = !!size.width && size.width < 768;

  return (
    <>
      {[...Array(itemsCount)]?.map((item) => (
        <ContentLoader
          speed={2}
          width={mobileScreen ? 320 : 600}
          height={72}
          key={item}
          className="py-8 md:px-6 px-3"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="72" y="0" rx="4" width="173" height="24" />
          <rect
            x="72"
            y="36"
            rx="4"
            width={mobileScreen ? 320 : 528}
            height="16"
          />
          <rect
            x="72"
            y="56"
            rx="4"
            width={mobileScreen ? 320 : 528}
            height="16"
          />
          <circle cx="24" cy="24" r="24" />
        </ContentLoader>
      ))}
    </>
  );
};
