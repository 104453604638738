import ReactDOM from "react-dom/client";
// import { BrowserRouter as Router } from "react-router-dom";

import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { history, setPostLoginUri, setupAxios } from "./utils";
import "./utils/i18";
import { BrowserRouter } from "./router/BrowserRouter";
import { AuthProvider } from "oidc-react";
import { oidcConfig } from "./config/oidc-auth";
import ProtectedRouter from "./router/ProtectedRouter";
import { PermissionsProvider } from "./context";

setupAxios(history);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

setPostLoginUri();

root.render(
  <BrowserRouter history={history}>
    <AuthProvider {...oidcConfig}>
      <PermissionsProvider>
        <ProtectedRouter>
          <App />
        </ProtectedRouter>
      </PermissionsProvider>
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
