interface IThreeDotsIcon {
  width?: number;
  height?: number;
}

export const ThreeDotsIcon: React.FC<IThreeDotsIcon> = ({ width, height }) => {
  return (
    <svg
      width={width || 12}
      height={height || 8}
      viewBox="0 0 12 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09091 3.09197C1.6934 3.09197 2.18182 2.60356 2.18182 2.00107C2.18182 1.39857 1.6934 0.910156 1.09091 0.910156C0.488417 0.910156 0 1.39857 0 2.00107C0 2.60356 0.488417 3.09197 1.09091 3.09197Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9091 3.09197C11.5116 3.09197 12.0001 2.60356 12.0001 2.00107C12.0001 1.39857 11.5116 0.910156 10.9091 0.910156C10.3067 0.910156 9.81824 1.39857 9.81824 2.00107C9.81824 2.60356 10.3067 3.09197 10.9091 3.09197Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99997 3.09197C6.60246 3.09197 7.09088 2.60356 7.09088 2.00107C7.09088 1.39857 6.60246 0.910156 5.99997 0.910156C5.39747 0.910156 4.90906 1.39857 4.90906 2.00107C4.90906 2.60356 5.39747 3.09197 5.99997 3.09197Z"
      />
    </svg>
  );
};
