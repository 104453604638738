import { FC } from "react";
import { cx } from "../../utils";

export enum FontWeight {
  light = "font-light",
  normal = "font-normal",
  medium = "font-medium",
  semiBold = "font-semibold",
  bold = "font-bold",
}

interface ITitle {
  text: string;
  noMargin?: boolean;
  mb?: string;
  className?: string;
  fontWeight?: FontWeight;
}

export const Title: FC<ITitle> = ({
  text,
  noMargin,
  mb,
  className,
  fontWeight = FontWeight.medium,
}) => {
  return (
    <h4
      className={cx([
        "text-black leading-9",
        mb ? mb : noMargin ? "mb-0" : "mb-5 sm:mb-12",
        fontWeight,
        className && className,
      ])}
    >
      {text}
    </h4>
  );
};
