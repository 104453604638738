import { FC } from "react";

export const Dots: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23077 6.46154C5.91051 6.46154 6.46154 5.91051 6.46154 5.23077C6.46154 4.55103 5.91051 4 5.23077 4C4.55103 4 4 4.55103 4 5.23077C4 5.91051 4.55103 6.46154 5.23077 6.46154Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7692 6.46154C19.449 6.46154 20 5.91051 20 5.23077C20 4.55103 19.449 4 18.7692 4C18.0895 4 17.5385 4.55103 17.5385 5.23077C17.5385 5.91051 18.0895 6.46154 18.7692 6.46154Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 6.46154C12.6798 6.46154 13.2308 5.91051 13.2308 5.23077C13.2308 4.55103 12.6798 4 12.0001 4C11.3203 4 10.7693 4.55103 10.7693 5.23077C10.7693 5.91051 11.3203 6.46154 12.0001 6.46154Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23077 20C5.91051 20 6.46154 19.449 6.46154 18.7692C6.46154 18.0895 5.91051 17.5385 5.23077 17.5385C4.55103 17.5385 4 18.0895 4 18.7692C4 19.449 4.55103 20 5.23077 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7692 20C19.449 20 20 19.449 20 18.7692C20 18.0895 19.449 17.5385 18.7692 17.5385C18.0895 17.5385 17.5385 18.0895 17.5385 18.7692C17.5385 19.449 18.0895 20 18.7692 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 20C12.6798 20 13.2308 19.449 13.2308 18.7692C13.2308 18.0895 12.6798 17.5385 12.0001 17.5385C11.3203 17.5385 10.7693 18.0895 10.7693 18.7692C10.7693 19.449 11.3203 20 12.0001 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23077 13.2308C5.91051 13.2308 6.46154 12.6797 6.46154 12C6.46154 11.3203 5.91051 10.7692 5.23077 10.7692C4.55103 10.7692 4 11.3203 4 12C4 12.6797 4.55103 13.2308 5.23077 13.2308Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7692 13.2308C19.449 13.2308 20 12.6797 20 12C20 11.3203 19.449 10.7692 18.7692 10.7692C18.0895 10.7692 17.5385 11.3203 17.5385 12C17.5385 12.6797 18.0895 13.2308 18.7692 13.2308Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 13.2308C12.6798 13.2308 13.2308 12.6797 13.2308 12C13.2308 11.3203 12.6798 10.7692 12.0001 10.7692C11.3203 10.7692 10.7693 11.3203 10.7693 12C10.7693 12.6797 11.3203 13.2308 12.0001 13.2308Z"
      />
    </svg>
  );
};
