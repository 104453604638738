import { useEffect, useState } from "react";
import { LiveError, LivePreview, LiveProvider } from "react-live-runner";
import { useFetch, usePreviewMode } from "../../hooks";
import { templatesApiUrl } from "../../utils";
import { Eye, EyeClosed } from "../icons";

export function PasswordField({
  name,
  validate,
  className,
  register,
}: {
  validate?: any;
  name: string;
  className: any;
  register?: any;
}) {
  const [showPassword, setShowPassword] = useState(true);
  const { isPreviewMode } = usePreviewMode();

  const {
    apiCall: getPasswordFieldTemplate,
    response: passwordFieldTemplate,
    loading: passwordFieldTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getPasswordFieldTemplate(templatesApiUrl("passwordField", isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scope = {
    register,
    className,
    setShowPassword,
    showPassword,
    validate,
    name,
    EyeClosed,
    Eye,
  };

  return (
    <>
      {!passwordFieldTemplateLoading && (
        <LiveProvider
          code={
            isPreviewMode
              ? passwordFieldTemplate?.previewHtml || "<></>"
              : passwordFieldTemplate?.html || "<></>"
          }
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
}
