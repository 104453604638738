import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IFormFooter } from "../../interfaces";
import { cancelBtnClasses, cx, primaryBtnClasses } from "../../utils";
import { Button, BtnStyle, BtnSize, BtnType } from "../";

export const FormFooter: FC<IFormFooter> = (props) => {
  const { saveBtnType, onSaveClick, saveBtnLabel, disabled } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex md:pl-48 px-0 sm:px-4 sm:justify-start justify-end">
      <Button
        type={saveBtnType || "submit"}
        onClick={onSaveClick}
        btnStyle={BtnStyle.primary}
        btnSize={BtnSize.nomral}
        disabled={disabled}
        className={cx(["flex items-center", primaryBtnClasses])}
      >
        <>
          {disabled && (
            <img
              src="/images/oval.svg"
              alt="Spinner"
              width={16}
              className="mr-2"
            />
          )}
          {saveBtnLabel || t("general.saveChanges")}
        </>
      </Button>

      <Button
        type="button"
        btnType={BtnType.secondary}
        btnStyle={BtnStyle.neutral}
        btnSize={BtnSize.nomral}
        onClick={() => navigate(-1)}
        className={cx(["sm:flex-none ml-4", cancelBtnClasses])}
      >
        {t<string>("general.cancel")}
      </Button>
    </div>
  );
};
