import { FC, PropsWithChildren } from "react";
import { useAuth } from "oidc-react";
import { Loader } from "../components";

const ProtectedRouter: FC<PropsWithChildren<{}>> = ({ children }) => {
  const auth = useAuth();
  if (auth?.userData) return <>{children && children}</>;
  else
    return <Loader className="min-h-screen flex items-center justify-center" />;
};
export default ProtectedRouter;
