import { FC } from "react";

interface IPageHeader {
  title: string;
  description: string;
  iconUrl: string;
}

export const PageHeader: FC<IPageHeader> = ({
  title,
  description,
  iconUrl,
}) => {
  return (
    <div className="relative pl-10 pb-10 pt-10 pr-14 sm:pr-64 shadow-card rounded-xl overflow-hidden">
      <h4 className="font-medium text-black">{title}</h4>
      <p className="text-sm text-neutral mt-1">{description}</p>

      {iconUrl && (
        <div className="absolute bottom-0 right-0 lg:right-16 md:w-unset">
          <img src={iconUrl} alt="Page Icon" className="w-16 sm:w-48" />
        </div>
      )}
    </div>
  );
};
